<template>
    <v-navigation-drawer
        id="core-navigation-drawer"
        v-model="drawer"
        :dark="barColor !== 'rgba(228, 226, 226, 1), rgba(255, 255, 255, 0.7)'"
        :expand-on-hover="expandOnHover"
        :src="barImage"
        mobile-breakpoint="960"
        app
        width="260"
        v-bind="$attrs"
    >
        <template v-slot:img="props">
            <v-img
                :gradient="`to bottom, ${barColor}`"
                v-bind="props"
            />
        </template>

        <v-list
            dense
            nav
        >
            <v-list-item>
                <v-list-item-avatar
                    class="align-self-center"
                    color="white"
                    contain
                >
                    <v-img
                        src="/img/logo-newlvl.png"
                    />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title
                        class="display-1"
                        style="line-height: 1.8rem;"
                    >
                        <span class="title-drawer">newLvL.net</span>
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="mb-1" />

        <v-list
            dense
            nav
        >
            <v-list-item
                :to="{name: 'Profile'}"
                active-class="primary white--text"
            >
                <v-list-item-avatar
                    class="align-self-center"
                    color="white"
                    contain
                >
                    <v-img
                        v-if="profile.avatar"
                        :src="`${ $store.state.serverPath }/storage/${ profile.avatar }`"
                    />
                </v-list-item-avatar>

                <v-list-item-content>
                    <v-list-item-title
                        v-if="profile.last_name"
                        class="display-1"
                    >
                        {{ profile.name }} {{ profile.last_name }}
                    </v-list-item-title>
                    <v-list-item-title
                        v-else
                        class="display-1"
                    >
                        {{ profile.name }}
                    </v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>

        <v-divider class="mb-2" />

        <!-- <v-list v-if="!packageAll.paytime">
            <div class="text-center">
                <v-btn color="green" @click="openDemo">Демо-доступ на 3 дня</v-btn>
            </div>
        </v-list> -->
        <v-list v-if="!packageAll.paytime || packageAll.paytime < datePay">
            <div class="text-center">
                <v-btn color="success" :to="{name: 'PayInstruments'}">Доступ к инструментам</v-btn>
            </div>
        </v-list>
        <v-divider class="mt-2" />

        <v-list
            expand
            nav
        >
            <v-list-item
                :to="{name: 'Dashboard'}"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-view-dashboard</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Главная</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <v-list-item
                :to="{name: 'Instruments'}"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-hammer-screwdriver</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Инструменты</v-list-item-title>
                </v-list-item-content>
            </v-list-item>

            <!-- <v-list-item
                v-if="!packageAll.paytime"
                :to="{name: 'Demo'}"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-hammer-screwdriver</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Демо-доступ</v-list-item-title>
                </v-list-item-content>
            </v-list-item> -->

            <v-list-item
                :to="{name: 'myProspects'}"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-account-multiple-plus</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Мои заявки</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                href="https://t.me/+gkSGErq2-BMxNTdi"
                target="_blanc"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-alarm-light</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Чат поддержки newlvl</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
            <v-list-item
                :to="{name: 'Support'}"
                active-class="primary white--text"
            >
                <v-list-item-icon>
                    <v-icon>mdi-alarm-light</v-icon>
                </v-list-item-icon>

                <v-list-item-content>
                    <v-list-item-title>Техподдержка</v-list-item-title>
                </v-list-item-content>
            </v-list-item>
        </v-list>
        <div class="px-5 mt-2" style="color: #ccc; text-align: center;">
            <p class="mb-1">время работы техподдержки:</p>
            <p class="mb-1">пн-пт с 10 до 17 мск,</p>
            <p>сб, вс - выходной</p>
        </div>
        <div class="footer-sidebar body-2 font-weight-light">
            &copy; {{ new Date().getFullYear() }}, newlvl.net, v1.0.0
        </div>
        <v-overlay :value="overlay">
            <v-progress-circular
                indeterminate
                color="blue"
            ></v-progress-circular>
        </v-overlay>
    </v-navigation-drawer>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import * as userService from '../../services/user_service'

export default {
    name: "Drawer",
    props: {
        expandOnHover: {
            type: Boolean,
            default: false,
        },
    },
    data: () => ({
        overlay: false,
        dateNow: new Date().toISOString().substr(0, 10),
    }),
    computed: {
        ...mapState(['barColor', 'barImage']),
        ...mapGetters({
            profile: 'user/profile',
            packageAll: 'user/getProfileAll',
        }),
        datePay() {
            let D = new Date()
            D.setDate(D.getDate() + 7)
            return D.toISOString().substr(0, 10)
        },
        drawer: {
            get () {
                return this.$store.state.drawer
            },
            set (val) {
                this.$store.commit('SET_DRAWER', val)
            },
        }
    },
    methods: {
        ...mapActions({
            loadPackageAll: 'user/loadProfileAll',
            addNotification: 'application/addNotification',
        }),
        openDemo() {
            this.overlay = true
            userService.updateDemoAll()
                .then((response) => {                    
                    if (response.data && response.data.success) {
                        this.errors = {}
                        this.overlay = false
                        this.addNotification({
                            show: true,
                            text: response.data.message,
                            color: 'success'
                        })
                    }
                    location.reload();
                })
                .catch((error) => {
                    switch (error.response.status) {
                        case 500:
                            this.overlay = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                        default:
                            this.overlay = false
                            this.addNotification({
                                show: true,
                                text: error.response.data.message,
                                color: 'error'
                            })
                            this.errors = error.response.data.errors
                            break
                    }
                })
        },
    },
    mounted() {
        this.loadPackageAll()
    },
}
</script>

<style lang="sass">
#core-navigation-drawer
    .v-list-group__header.v-list-item--active:before
        opacity: .24

    .v-list-item
        &__icon--text,
        &__icon:first-child
            justify-content: center
            text-align: center
            width: 20px

    .v-list--dense
        .v-list-item
            &__icon--text,
            &__icon:first-child
                margin-top: 10px

    .v-list-group--sub-group
        .v-list-group__header

            .v-list-item__icon--text
                margin-top: 19px
                order: 0

            .v-list-group__header__prepend-icon
                order: 2
.title-drawer
    font-size: 26px

.footer-sidebar
    color: gray
    position: absolute
    bottom: 0
    padding: 20px
</style>
